import { ClientCategory } from "@fundo/app/enums/client-category-enum";
import { VerificationFlow } from '@fundo/app/enums/verification-flow-enum';

export enum LeadStatusNumber {
  FormRedirect = 50,
  ConfirmationInfo = 100,
  CustomerVerification = 110,
  PartialSSNValidationFailed = 125,
  IncomeInfo = 150,
  LightningVerification = 160,
  EmailConfirmation = 165,
  BankVerification = 400,
  PlaidConnection = 420,
  PlaidReportPreparation = 425,
  ManualOfferCalculation = 450,
  PlaidReportError = 475,
  OfferCalculation = 500,
  BankAccountConfirmation = 520,
  RevenueVerification = 530,
  OfferConfirmation = 550,
  ConfirmPersonalDetails = 610,
  AgreementViewed = 650,
  Denied = 700,
  Withdrawn = 725,
  DocumentVerification = 940,
  AdditionalInfoPending = 950,
  PendingReferences = 960,
  Completed = 970,
  Funded = 1000,
  Voided = 1100,
  PaidOff = 1200,
}


export interface LeadStatusResponse {
  id: LeadStatusNumber;
  value: string;
  publicId: string;
  verificationFlow: VerificationFlow;
  clientCategory: ClientCategory;
  language?: string;
  userId?: number;
  isExpiredPlaidReport?: boolean;
  timeInStatus: number;
  tag?: number;
  denialReason?: string;
  clientCategoryId?: number;
  error?: {
    errorKey: number;
    confirmationToken: string;
  };
}
